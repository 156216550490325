import React from 'react';
import './css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faDribbble, faLinkedin, faYoutube, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6>Sobre <i class="fa-solid fa-circle-info"></i></h6>
            <p class="text-justify">Municipio de Parari, sua história começou na primeira metade do século XIX, sua emancipação proporcionou-se no dia 29 de abril de 1994, sendo sua instalação no dia 01 de janeiro de 1997. As origens do atual Município de Parari, quando se tem notícia de um grande batizado na “Casa Baixa” de propriedade do “Velho Cordeiro”. O seu filho, Alípio Cordeiro, ordenou-se sacerdote e voltou a Paraíba onde foi vigário de Monteiro nos anos 1878/1879. As terras boas para o cultivo logo chamaram atenção de moradores de outras regiões que se mudaram para ali e instalaram suas propriedades. Foram pioneiros da Povoação o Major Isidoro, Coronel Antônio da Costa Maracajá, Francisco da Chagas Brito, Antônio Torreão Vicente de Holanda Montenegro e Manoel Alípio de Farias, sendo este último o primeiro comerciante.</p>
           
          </div>

          <div class="col-xs-6 col-md-3">
            <h6>Secretarias <i class="fa-solid fa-user"></i></h6>
            <ul class="footer-links">
            <Link to={"/secretarias/administracao"} className="dropdown-item">Administração</Link>
          <Link to={"/secretarias/assistenciasocial"} className="dropdown-item">Assistencia Social</Link>
          <Link to={"#"} className="dropdown-item">Controladoria</Link>
          <Link to={"/secretarias/cultura"} className="dropdown-item">Cultura e Turismo</Link>
          <Link to={"/secretarias/desenvolvimentorural"} className="dropdown-item">Desenvolvimento Rural</Link>
          <Link to={"/secretarias/educacao"} className="dropdown-item">Educação</Link>
          <Link to={"/secretarias/financas"} className="dropdown-item">Finanças</Link>
          <Link to={"#"} className="dropdown-item">Infraestrutura</Link>
          <Link to={"#"} className="dropdown-item">Recursos Humanos</Link>
          <Link to={"/secretarias/saude"} className="dropdown-item">Saúde</Link>
            
            </ul>
          </div>

          <div class="col-xs-4 col-md-3">
            <h6>Links úteis <i class="fa-solid fa-user"></i></h6>
            <ul class="footer-links">
            <li><Link  to={"/arquivos/contratos"}>Contratos</Link></li>
             <li><Link to={"/arquivos/frota"}>Frota Municipal</Link></li>
           <li><Link  to={"/rh/cc"}>Contra-cheque</Link></li>
           <li><Link  to={"/transparencia"}>Transparência Fiscal</Link></li>
           <li><Link className="dropdown-item" to={"/legislacao/codigotributario"}>Codigo Tributario</Link></li>
           
            </ul>
          </div>
        </div>
        <hr></hr>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2024 - Prefeitura Municipal de Parari - CNPJ: 01.612.532/0001-42 - Controle de dados
         <a href="https://www.conectadocomanoticia.com.br"> <i class="fa-brands fa-dev fa-fade"></i> AGENCIA CONECTADO LTDA.</a>.
            </p>
          </div>

          <div class="col-md-2 col-sm-6 col-xs-12">

            <ul class="social-icons">
            <li><a className="facebook" href=""><FontAwesomeIcon icon={faFacebook} /></a></li>
            <li><a className="instagram" href=""><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li><a className="youtube" href=""><FontAwesomeIcon icon={faYoutube} /></a></li>
            <li><a className="whatsapp" href="#"><FontAwesomeIcon icon={faWhatsapp} /></a></li>
            </ul>
            
          </div>
        </div>
      </div>    
</footer>
 
  );
}

export default Footer;
