import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './css/cabecalho.css';
import './css/slide.css';
import './css/Menu.css';
import './css-wesome/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { faFacebook, faInstagram, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import * as MuiIcons from '@mui/icons-material';

export default function Cabecalho({ config }) {
  const tempo = [1 * 60 * 60]

  const [maintenanceActive, setMaintenanceActive] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [maintenanceTime, setMaintenanceTime] = useState(0);
  const [maintenanceColor, setMaintenanceColor] = useState('');
  const [maintenanceTimeActive, setMaintenanceTimeActive] = useState(false);

  const isDesktop = window.innerWidth > 768;
  const currentDate = new Date().toLocaleDateString('pt-BR', {
    weekday: 'long',
    day: 'numeric',
    month: isDesktop ? 'long' : 'numeric',
    year: 'numeric',
  });

  const estiloIcones = {
    color: 'white',
    fontSize: '24px',
    marginLeft: '10px'
  };

  const estiloRedesSociais = {
    display: 'flex',
    alignItems: 'center',
    marginRight: 50
  };

  const estiloMensagemManutencao = {
    color: 'white',
    backgroundColor: maintenanceActive ? maintenanceColor : 'blue',
    padding: '10px',
    textAlign: 'center',
  };


  useEffect(() => {
    const databaseInstance = getDatabase(firebaseApp);
    const maintenanceRef = ref(databaseInstance, `${KEY_REF}/manutencao`);

    onValue(maintenanceRef, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        setMaintenanceActive(data.ativo);
        setMaintenanceMessage(data.msg);
        setMaintenanceTime(data.tempo);
        setMaintenanceColor(data.cor);
        setMaintenanceTimeActive(data.time_ativo);
      }
    });
  }, []);
  return (


    <header>
      



      <nav className="navbar navbar-expand-lg navbar-dark header-background" style={{ background: config.background }}>
   
        <div className="container">
          <span className="navbar-brand"> </span>

          <span className='estiloData'><i class="fa-solid fa-calendar-days"></i>  Parari-PB, {currentDate}</span>

          <div style={estiloRedesSociais}>

            <Link to={"/transparencia"}><button className="btn btn-outline-light ">Transparência Fiscal </button></Link>
           {/*  <Link to={"#"}> - </Link>
            <Link to={"/app/notasfiscais"}><button className="btn btn-outline-light ">NFS-E</button></Link> */}


            <div className='linksredes'>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} style={estiloIcones} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} style={estiloIcones} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} style={estiloIcones} />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} style={estiloIcones} />
              </a>
            </div>

          </div>




        </div>
      </nav>

      <div className='slide'>
        <Link to={"/"}>
          <img className='imagem' src={config.banner} alt="Banner"></img>
        </Link>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ background: config.background }}>
        <div className="container-fluid" style={{paddingTop: 10, paddingBottom: 10}}>
          {/* <Link className="navbar-brand " to="/">
            <img
              src="/imagens/parariwhite.png"
              className={`logoparari ${window.innerWidth <= 768 ? 'small-logo' : ''}`}
              alt="Parari - PB"
              width="230px"

            /></Link> */}
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div style={{ marginLeft: 15 }} className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav me-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link active text-white" to={"/"}><i className="fa fa-home"></i></Link>


              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="prefeituraDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Prefeitura
                </a>
                <ul className="dropdown-menu" aria-labelledby="prefeituraDropdown">
                  <Link to={"#"} className="dropdown-item">Historia</Link>
                  <Link to={"/prefeitura/prefeito"} className="dropdown-item">Prefeito</Link>
                  <Link to={"#"} className="dropdown-item">Vice-Prefeito</Link>
                  <Link to={"#"} className="dropdown-item">Contato</Link>

                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="secretariasDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Secretarias
                </a>
                <ul className="dropdown-menu" aria-labelledby="secretariasDropdown">
                  <Link to={"/secretarias/administracao"} className="dropdown-item">Administração</Link>
                  <Link to={"/secretarias/assistenciasocial"} className="dropdown-item">Assistencia Social</Link>

                  <Link to={"/secretarias/agricultura"} className="dropdown-item">Agricultura</Link>
  {/*                 <Link to={"/secretarias/desenvolvimentorural"} className="dropdown-item">Desenvolvimento Rural</Link> */}
                  <Link to={"/secretarias/educacao"} className="dropdown-item">Educação</Link>
                  <Link to={"/secretarias/financas"} className="dropdown-item">Finanças</Link>
                {/*   <Link to={"/secretarias/meioambiente"} className="dropdown-item">Meio Ambiente</Link> */}
                  <Link to={"#"} className="dropdown-item">Infraestrutura</Link>

                  <Link to={"/secretarias/saude"} className="dropdown-item">Saúde</Link>

                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Legislação

                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                <li><Link className="dropdown-item" to={"/legislacao/aldir_blanc"}>Lei Aldir Blanc</Link></li>
                 
                  <li><Link className="dropdown-item" to={"/legislacao/leismunicipais"}>Leis muncipais</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/leiorganica"}>Leis organica</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/diariosoficial"}>Diarios oficial</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/codigotributario"}>Codigo Tributario</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/controleinterno"}>Controle Interno Municipal</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/ppa"}>PPA</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/loa"}>LOA</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/ldo"}>LDO</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/rreo"}>RREO</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/rgf"}>RGF</Link></li>

                </ul>

              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="#" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Documentação
                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                  <li><Link className="dropdown-item" to={"/arquivos/quadro"}>Quadro Funcional</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/contratos"}>Contratos</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/decretos"}>Decretos</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/editais"}>Editais</Link></li>
                  <li><Link className="dropdown-item" to={"/arquivos/frota"}>Frota Municipal</Link></li>
                  <li><Link className="dropdown-item" to={"/legislacao/portarias"}>Portarias</Link></li>
                  <li><Link className="dropdown-item" to={"/processo_seletivo"}>Processos Seletivos</Link></li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle active text-white" href="/recursoshumanos" id="legislacaoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Recursos Humanos
                </a>
                <ul className="dropdown-menu" aria-labelledby="legislacaoDropdown">
                  <li><Link className="dropdown-item" to={"/rh/cc"}>Contra-cheque</Link></li>
                </ul>
              </li>

              {config.destaque && config.destaque.active && (
                <li className="nav-item">
                  <Link className="nav-link active text-white fw-bold" to={config.destaque.link}>
                    {React.createElement(MuiIcons[config.destaque.icon])} {config.destaque.name}
                  </Link>
                </li>
              )}

            </ul>
            <form className="d-flex" role="search">
              <input className="form-control me-2" type="search" placeholder="Pesquise aqui..." aria-label="Search" />
              <button className="btn btn-custom-meu" type="submit">Pesquisar</button>
            </form>
          </div>
        </div>
      </nav>




      {maintenanceActive && maintenanceTime > 0 && (
        <div style={estiloMensagemManutencao}>
          <i class="fa-solid fa-circle-exclamation"></i>
          {" " + maintenanceMessage}
          {maintenanceTimeActive ? (
            <b style={{ marginLeft: 8 }}>
              <i class="fa-regular fa-clock"></i> Tempo restante Estimado: {Math.floor(maintenanceTime / 3600)}h {Math.floor((maintenanceTime % 3600) / 60)}m {maintenanceTime % 60}s
            </b>
          ) : null}
        </div>
      )}



    </header>
  );
}
