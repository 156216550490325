import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';

const mesesNumeros = {
	janeiro: 1,
	fevereiro: 2,
	marco: 3,
	abril: 4,
	maio: 5,
	junho: 6,
	julho: 7,
	agosto: 8,
	setembro: 9,
	outubro: 10,
	novembro: 11,
	dezembro: 12,
};

const converterMesParaNumero = (mes) => {
	if (typeof mes === 'string') {
		return mesesNumeros[mes.toLowerCase()] || 0;
	}
	return mes;
};

const converterNumeroParaMes = (numero) => {
	const meses = Object.keys(mesesNumeros);
	return meses.find(mes => mesesNumeros[mes] === numero);
};

export default function LeisMunicipais() {
	const [newsData, setNewsData] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(2000);
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
	const [selectedYear, setSelectedYear] = useState('');

	useEffect(() => {
		const fetchData = async () => {
			try {
				const databaseInstance = getDatabase(firebaseApp);
				const noticiasRef = ref(databaseInstance, `${KEY_REF}/leismunicipais`);

				onValue(noticiasRef, (snapshot) => {
					const data = snapshot.val();

					if (data) {
						const newsArray = Object.keys(data).map((key) => ({
							id: key,
							title: data[key].nome,
							mes: data[key].mes,
							ano: data[key].ano,
							href: data[key].href,
						}));

						// Ordenar os dados por ano e mês decrescente
						newsArray.sort((a, b) => {
							const anoA = parseInt(a.ano, 10);
							const anoB = parseInt(b.ano, 10);
							const mesA = converterMesParaNumero(a.mes);
							const mesB = converterMesParaNumero(b.mes);

							if (anoA !== anoB) {
								return anoB - anoA; // Ordem decrescente de anos
							}
							return mesB - mesA; // Ordem decrescente de meses
						});

						setNewsData(newsArray);
					}
				});
			} catch (error) {
				console.error('Erro ao carregar notícias:', error);
			}
		};

		fetchData();
	}, []);

	const paginatedNewsData = newsData.slice(0, itemsPerPage);

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(parseInt(e.target.value, 10));
	};

	const handleSort = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ key, direction });
	};

	const handleYearChange = (e) => {
		setSelectedYear(e.target.value);
	};

	const uniqueYears = [...new Set(newsData.map((news) => news.ano))];

	const filteredNewsData = () => {
		let filteredItems = [...paginatedNewsData];

		if (selectedYear) {
			filteredItems = filteredItems.filter((news) => {
				if (typeof news.ano === 'string') {
					return news.ano === selectedYear;
				} else {
					return news.ano === parseInt(selectedYear, 10);
				}
			});
		}

		return filteredItems;
	};

	const sortedNewsData = () => {
		const sortableItems = [...filteredNewsData()];

		if (sortConfig.key !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'asc' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'asc' ? 1 : -1;
				}
				return 0;
			});
		}

		return sortableItems;
	};

	return (
		<main>
			<section className="ftco-section">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-6 text-center mb-5">
							<br /> <br />
							<div className="text-with-gradient">
								<div className="gradient-line"></div>
								<div className="text-container">
									<span>LEIS MUNICIPAIS</span>
								</div>
								<div className="gradient-line"></div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="table-wrap">
								<label htmlFor="itemsPerPage">Itens por página:</label>
								<select
									id="itemsPerPage"
									name="itemsPerPage"
									value={itemsPerPage}
									onChange={handleItemsPerPageChange}
								>
									<option value="2000">Filtrar por todos</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="50">50</option>
									<option value="100">100</option>
								</select>

								<label htmlFor="yearSelector"> - Filtrar por ano:</label>
								<select
									id="yearSelector"
									name="yearSelector"
									value={selectedYear}
									onChange={handleYearChange}
								>
									<option value="">Todos os anos</option>
									{uniqueYears.map((year) => (
										<option key={year} value={year}>
											{year}
										</option>
									))}
								</select>

								<table className="table">
									<thead className="thead-primary">
										<tr>
											<th onClick={() => handleSort('title')}>Nome</th>
											<th onClick={() => handleSort('mes')}>Mês</th>
											<th onClick={() => handleSort('ano')}>Ano</th>
											<th>Arquivo</th>
										</tr>
									</thead>
									<tbody>
										{sortedNewsData().map((news) => (
											<tr key={news.id}>
												<td style={{ fontWeight: 'bold' }}>{news.title.toUpperCase()}</td>
												<td>{converterNumeroParaMes(converterMesParaNumero(news.mes))}</td>
												<td>{news.ano}</td>
												<td><a href={"http://www.parari.pb.gov.br/" + news.href}>Download</a></td>
											</tr>
										))}
									</tbody>
								</table>
								<br /> <br />
							</div>
						</div>
					</div>
					<br /> <br /> <br /> <br />
				</div>
			</section>
		</main>
	);
}
